import { inDevelopment } from 'services/env'

export const log = (msg, level = null, opts = null) => {
  getLogger().then(log => log(msg, level, opts))
}

export const getLogger = async (defaultOpts = null) => {
  if (typeof window === 'undefined') {
    return await getServerLogger(defaultOpts)
  } else {
    return await getClientLogger(defaultOpts)
  }
}

const getServerLogger = async (defaultOpts = null) => {
  if (typeof window === 'undefined') {
    const logger = (await import('services/server_logger')).default

    return (msg, level = null, opts = null) => {
      try {
        opts = { ...defaultOpts, ...opts }
        level = getLevel(logger, level, opts)

        const message = msg ?? opts?.error?.message ?? '-'
        const error = opts?.error

        const extra = {
          meta: {
            ...opts,
            error: serializeError(error),
          },
        }

        logger[level]?.(message, extra)
      } catch (err) {
        try {
          logger.error('Failed to log a message', {
            error: { message: err.message, stack: err.stack },
          })
        } finally {
          console.error(err)
        }
      }
    }
  }
}

const getClientLogger = async (defaultOpts = null) => {
  if (typeof window !== 'undefined') {
    const { captureException } = await import('@sentry/browser')

    return (msg, level = null, opts = null) => {
      try {
        opts = { ...defaultOpts, ...opts }
        level = getLevel(console, level, opts)

        const message = msg ?? opts?.error?.message ?? '-'
        const error = opts?.error

        const extra = {
          ...opts,
          ...serializeError(error),
          message,
        }

        if (inDevelopment) {
          // eslint-disable-next-line no-console
          console[level]?.(message, error, extra)
        }

        if (error || level === 'error') {
          captureException({ error, extra })
        }
      } catch (err) {
        try {
          captureException({
            error: err,
            extra: { message: 'Failed to log a message' },
          })
        } finally {
          console.error(err)
        }
      }
    }
  }
}

const serializeError = err => {
  if (!err) {
    return null
  }

  return {
    type: err.constructor?.name,
    message: err.message,
    stack: err.stack,
    meta: err.meta,
  }
}

const getLevel = (logger, level, opts) => {
  if (typeof level === 'string') {
    level = level.toLowerCase()
  }

  if (level === 'log') {
    return 'info'
  }

  if (typeof logger?.[level] === 'function') {
    return level
  }

  return opts?.error ? 'error' : 'info'
}
