export const letters = 'abcdefghijklmnopqrstuvwxyz'

export const countries = [
  {
    name: 'United States',
    slug: 'usa',
  },
]

export const gender = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Non-Binary', value: 'non-binary' },
]

export const states = [
  { name: 'Alabama', slug: 'alabama', abbr: 'al' },
  { name: 'Alaska', slug: 'alaska', abbr: 'ak' },
  { name: 'Arizona', slug: 'arizona', abbr: 'az' },
  { name: 'Arkansas', slug: 'arkansas', abbr: 'ar' },
  { name: 'California', slug: 'california', abbr: 'ca' },
  { name: 'Colorado', slug: 'colorado', abbr: 'co' },
  { name: 'Connecticut', slug: 'connecticut', abbr: 'ct' },
  { name: 'Delaware', slug: 'delaware', abbr: 'de' },
  { name: 'District Of Columbia', slug: 'district-of-columbia', abbr: 'dc' },
  { name: 'Florida', slug: 'florida', abbr: 'fl' },
  { name: 'Georgia', slug: 'georgia', abbr: 'ga' },
  { name: 'Hawaii', slug: 'hawaii', abbr: 'hi' },
  { name: 'Idaho', slug: 'idaho', abbr: 'id' },
  { name: 'Illinois', slug: 'illinois', abbr: 'il' },
  { name: 'Indiana', slug: 'indiana', abbr: 'in' },
  { name: 'Iowa', slug: 'iowa', abbr: 'ia' },
  { name: 'Kansas', slug: 'kansas', abbr: 'ks' },
  { name: 'Kentucky', slug: 'kentucky', abbr: 'ky' },
  { name: 'Louisiana', slug: 'louisiana', abbr: 'la' },
  { name: 'Maine', slug: 'maine', abbr: 'me' },
  { name: 'Maryland', slug: 'maryland', abbr: 'md' },
  { name: 'Massachusetts', slug: 'massachusetts', abbr: 'ma' },
  { name: 'Michigan', slug: 'michigan', abbr: 'mi' },
  { name: 'Minnesota', slug: 'minnesota', abbr: 'mn' },
  { name: 'Mississippi', slug: 'mississippi', abbr: 'ms' },
  { name: 'Missouri', slug: 'missouri', abbr: 'mo' },
  { name: 'Montana', slug: 'montana', abbr: 'mt' },
  { name: 'Nebraska', slug: 'nebraska', abbr: 'ne' },
  { name: 'Nevada', slug: 'nevada', abbr: 'nv' },
  { name: 'New Hampshire', slug: 'new-hampshire', abbr: 'nh' },
  { name: 'New Jersey', slug: 'new-jersey', abbr: 'nj' },
  { name: 'New Mexico', slug: 'new-mexico', abbr: 'nm' },
  { name: 'New York', slug: 'new-york', abbr: 'ny' },
  { name: 'North Carolina', slug: 'north-carolina', abbr: 'nc' },
  { name: 'North Dakota', slug: 'north-dakota', abbr: 'nd' },
  { name: 'Ohio', slug: 'ohio', abbr: 'oh' },
  { name: 'Oklahoma', slug: 'oklahoma', abbr: 'ok' },
  { name: 'Oregon', slug: 'oregon', abbr: 'or' },
  { name: 'Pennsylvania', slug: 'pennsylvania', abbr: 'pa' },
  { name: 'Puerto Rico', slug: 'puerto-rico', abbr: 'pr' },
  { name: 'Rhode Island', slug: 'rhode-island', abbr: 'ri' },
  { name: 'South Carolina', slug: 'south-carolina', abbr: 'sc' },
  { name: 'South Dakota', slug: 'south-dakota', abbr: 'sd' },
  { name: 'Tennessee', slug: 'tennessee', abbr: 'tn' },
  { name: 'Texas', slug: 'texas', abbr: 'tx' },
  { name: 'Utah', slug: 'utah', abbr: 'ut' },
  { name: 'Vermont', slug: 'vermont', abbr: 'vt' },
  { name: 'Virgin Islands', slug: 'virgin-islands', abbr: 'vi' },
  { name: 'Virginia', slug: 'virginia', abbr: 'va' },
  { name: 'Washington', slug: 'washington', abbr: 'wa' },
  { name: 'West Virginia', slug: 'west-virginia', abbr: 'wv' },
  { name: 'Wisconsin', slug: 'wisconsin', abbr: 'wi' },
  { name: 'Wyoming', slug: 'wyoming', abbr: 'wy' },
]

export const months = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
]

export const days = new Array(31).fill(0).map((i, num) => {
  num = num + 1
  return {
    label: num,
    value: num < 10 ? '0' + num : num,
  }
})

export const COLORS = {
  brown: '#B5A598',
  darkBrown: '#947D6A',
  lightGray: '#edeae1',
  offWhite: '#F4F2ED',
  darkGray: '#373737',
  peach: '#ffd7ba',
  black: '#000',
  white: '#fff',
  red: '#A52A2A',
  highlight: '#FFFBCC',
  creamA50: '#FEF9F4',
  creamA100: '#FEF5EB',
  creamA200: '#FFF0DF',
  creamA300: '#FFE6CC',
  creamA400: '#FFDDBA',
  creamB50: '#FAF8F4',
  creamB100: '#F8F3EB',
  creamB200: '#F4EBDD',
  creamB300: '#EEDFCB',
  creamB400: '#E7D4B8',
  creamC50: '#F7F7F4',
  creamC100: '#F2F1EB',
  creamC200: '#EDEAE1',
  creamC300: '#E3DED1',
  creamC400: '#D9D3C1',
  yellowA50: '#FFF2E0',
  yellowA100: '#FFEBCC',
  yellowA200: '#FFE4BA',
  yellowA300: '#FFDBA3',
  yellowA400: '#FFD390',
  yellowB50: '#FFF0DF',
  yellowB100: '#FFE6CC',
  yellowB200: '#FFDDBA',
  yellowB300: '#FFD2A3',
  yellowB400: '#FFC88E',
  yellowC50: '#FFEDDF',
  yellowC100: '#FFE2CC',
  yellowC200: '#FFD7BA',
  yellowC300: '#FFCAA3',
  yellowC400: '#FFBE8E',
  orangeA50: '#FDDFCB',
  orangeA100: '#FCD3B4',
  orangeA200: '#F8B889',
  orangeA300: '#FAA369',
  orangeA400: '#FA905C',
  orangeB50: '#EBD8CB',
  orangeB100: '#E2C8B4',
  orangeB200: '#DAB092',
  orangeB300: '#C69472',
  orangeB400: '#C07E5C',
  orangeC50: '#DDD5D0',
  orangeC100: '#CEC4BB',
  orangeC200: '#B5A598',
  orangeC300: '#978272',
  orangeC400: '#7D6454',
  brownA50: '#B87C5E',
  brownA100: '#8D5F48',
  brownA200: '#6B4837',
  brownA300: '#442E23',
  brownA400: '#2D1E17',
  brownB50: '#8C6E5F',
  brownB100: '#6A5348',
  brownB200: '#513F37',
  brownB300: '#392C26',
  brownB400: '#221A17',
  gray50: '#727272',
  gray100: '#565656',
  gray200: '#373737',
  gray300: '#232323',
  gray400: '#0F0F0F',
}

export const ALGOLIA_ATTRIBUTES = [
  { attribute: 'procedure_areas.name', label: 'Areas:' },
  { attribute: 'invasive', label: 'Invasiveness' },
  { attribute: 'avg_procedure_cost', label: 'Cost' },
  { attribute: 'is_surgical', label: 'Surgical' },
]

export const BREAKPOINTS = {
  lg: '1200px',
  md: '992px',
  sm: '768px',
  xs: '576px',
}

export const cardColors = [
  '#FFE6CC',
  '#EEDFCB',
  '#E3DED1',
  '#FFDDBA',
  '#E7D4B8',
  '#D9D3C1',
  '#FFEBCC',
  '#FFE6CC',
  '#FFE2CC',
  '#FFE4BA',
  '#FFDDBA',
  '#FFD7BA',
  '#FFDBA3',
  '#FFD2A3',
  '#FFCAA3',
  '#FFD390',
  '#FFC88E',
  '#FFBE8E',
  '#FDDFCB',
  '#EBD8CB',
  '#DDD5D0',
  '#FCD3B4',
  '#E2C8B4',
  '#CEC4BB',
  '#F8B889',
  '#DAB092',
  '#B5A598',
]

export const NAV_Z_INDEX = 50

export const CONFIG = {
  provider: {
    BOARD_CERTIFICATIONS: {},
    DEGREE_TYPES: {},
    FELLOWSHIP_TRAINED_IN: {},
    GENDER: {},
    PROVIDER_LICENSE: {},
    PROVIDER_TYPES: {},
    SALUTATION: {},
    SPECIALTIES: {},
    LANGUAGES: {},
    NAME_SUFFIX: {},
  },
  practice: {
    PRACTICE_TYPES: {},
  },
  procedure: {
    ANESTHESIA_TYPES: {},
    FREQUENCY: {},
    TIMESPAN: {},
  },
  media: {
    CATEGORIES: {},
    TYPES: {},
    FLAG_REASONS: {},
    orderByDirections: {},
    orderByFields: {},
  },
  author: {
    VALID_AUTHOR_TYPES: {},
  },
  consultation: {
    PREFERRED_CONTACT_METHODS: {},
  },
}

export const CONSULTATION = {
  MAX_MINUTES_EARLY: 60,
  MAX_MINUTES_LATE: 60,
}

export const navAreas = [
  {
    label: 'Chin',
    params: { area: 'Chin' },
  },
  {
    label: 'Eyes',
    params: { area: 'Eyes' },
  },
  {
    label: 'Face',
    params: { area: 'Face' },
  },
  {
    label: 'Hair',
    params: { area: 'Hair' },
  },
  {
    label: 'Lips',
    params: { area: 'Lips' },
  },
]

export const COLLECTIONS_ERROR = [{ title: 'ERROR', route: '' }]

export const CLOUDINARY = 'https://res.cloudinary.com/aedit/image/upload'
export const CLOUDINARY_CORE_INIT_OPTIONS = {
  cloud_name: 'aedit',
  quality: 'auto:good',
  dpr: 'auto',
  fetch_format: 'auto',
  width: 'auto',
  secure: true,
  crop: 'limit',
}
export const CLOUDINARY_REACT_INIT_OPTIONS = {
  cloudName: 'aedit',
  quality: 'auto:good',
  dpr: 'auto',
  fetchFormat: 'auto',
  width: 'auto',
  secure: true,
  crop: 'limit',
}

export const STATIC_MAP_URL = 'https://maps.googleapis.com/maps/api/staticmap'
export const ITUNES_URL = 'https://aedit.onelink.me/E6Rw/efba578c'
export const ACCESS_TOKEN = 'aeditAccessToken'
export const FLAGSMITH_TOKEN = 'flagsmithToken'
export const USER_TYPE = 'userType'
export const USER_TYPES = {
  CANDIDATE: 'candidate',
  PROVIDER: 'provider',
  USER: 'candidate',
}
export const AEDIT_ANALYTICS_URL = '/analytics/track'

// append destination address to get directions
export const GOOGLE_MAPS_DIRECTION_URL = `https://www.google.com/maps/dir/?api=1&destination=`

export const GOOGLE_MAPS_CALLBACK = 'onGoogleMapsInitialized'
export const GOOGLE_PLACES_CALLBACK = 'onGooglePlacesInitialized'
export const GOOGLE_MAPS_SDK_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=places&callback=${GOOGLE_MAPS_CALLBACK}`

// Fix for "Broken image symbol"
// https://github.com/aFarkas/lazysizes#broken-image-symbol
export const EMPTY_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

export const LINKEDIN_URL = 'https://www.linkedin.com/company/the-aedit/'
export const FACEBOOK_URL = 'https://www.facebook.com/theaedit'
export const TWITTER_URL = 'https://twitter.com/theaedit'
export const INSTAGRAM_URL = 'https://www.instagram.com/aedit/'
export const PINTEREST_URL = 'https://www.pinterest.com/theaedit/'
export const TIKTOK_URL = 'https://www.tiktok.com/@the_aedit'
export const YOUTUBE_URL = 'https://www.youtube.com/@aedit7914'
export const APP_STORE_URL = 'https://aedit.onelink.me/E6Rw/efba578c'

export const MAX_USER_CARDS = 3

export const PROVIDER_PROTECTED_ROUTES = ['/provider-portal/']
export const USER_PROTECTED_ROUTES = ['/user/']
export const PUBLIC_ROUTES = ['/user/scan-share/'] // override for otherwise protected routes

export const CYPRESS_META_STRIPE_KEY = 'CypressMeta_Stripe'

export const SUPPORT_EMAIL = 'support@cs.aedit.com'
export const CLIENT_SERVICES_EMAIL = 'clientservices@aedit.com'
export const SUPPORT_GENERAL_PHONE = '888.847.6537'

export const MAX_PROVIDER_HEROS = 4

export const DEFAULT_COVER_IMAGE =
  'https://s3.amazonaws.com/assets.aedit.com/website/pages/providers/provider_profile/hero_image/default_hero.jpg'

export const DEFAULT_PROCEDURE_IMAGE_BEURO = '/website/pages/procedures/default_image_beuro.jpg'
export const DEFAULT_CHILD_PROCEDURE_IMAGE_BEURO =
  '/website/pages/procedures/child_default_image_beuro.jpg'

export const MB = 1000 * 1000

export const MOBILE_BREAKPOINT = 768

export const INITIAL_LD_FLAGS = Object.freeze(
  process.env.INITIAL_LD_FLAGS ? JSON.parse(process.env.INITIAL_LD_FLAGS) : {}
)

export const INTERCOM_ROUTE_BLACKLIST = new Set([
  '/user/consultations/[id]/video/live',
  '/provider-portal/consultations/[id]/video/live',
  '/provider-portal/consultations/[id]/morph',
])

export const AEDIT_FEE = 100

// Taken from https://urlregex.com/ and slightly modified to remove internal capture groups.
export const URL_REGEX_NO_CAP_GRP =
  /((?:(?:[A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)(?:(?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/g

export const PLAN_FLAGS = Object.freeze({
  BEFORE_AND_AFTERS: 'before-and-afters',
  PROVIDER_ABOUT_PICTURES: 'provideraboutpictures',
  WEB_MORPHING: 'webmorphing',
})

export const GOOGLE_LOGIN_URI = new URL('/google-login', process.env.WEB_HOST).toString()
export const GOOGLE_LOGIN_STATE_COOKIE = 'aeditGoogleLoginState'

export const AGREEMENT_UPDATE_TYPES = {
  PROVIDER_VIEW: 'provider_view',
  PROVIDER_SIGN: 'provider_sign',
}

export const FALLBACK_HERO_RELATIVE_URL =
  'assets.aedit.com/website/pages/provider-search/fallback-card-hero.png'

export const EDITORIAL_PROCESS_HERO_RELATIVE_URL =
  'assets.aedit.com/website/pages/editorial-process/ep-hero.png'

export const EDITORIAL_TEAM_HERO_RELATIVE_URL =
  'https://res.cloudinary.com/aedit/image/upload/ar_9:2,c_crop,dpr_auto,f_auto,g_faces,q_auto:best,w_auto/v1/production/website/practices/hero_images/azymfxfh1_bzq8JC6JiO.png'

export const BEURO_FAVICON_URL =
  'https://res.cloudinary.com/aedit/image/upload/c_limit,dpr_auto,f_auto,w_auto/v1/website/favicon_beuro.ico'

export const GOOGLE_ADSENSE_ID = process.env.GOOGLE_ADSENSE_ID

export const ADSENSE_AD_SLOTS = {
  PROCEDURE_CONCERN_ABOVE_SKINNY: 8255082702,
  PROCEDURE_CONCERN_ABOVE_SPECIFICS: 5656839523,
  PROCEDURE_CONCERN_BELOW_SPECIFICS: 2783264177,
  PROCEDURE_CONCERN_BELOW_SOURCES: 6822334115,
  PROCEDURE_CONCERN_BELOW_PROVIDERS_NEAR_YOU: 8313162141,
  GLOBAL_ABOVE_FOOTER: 2587823058,
}

export const SUBSCRIPTION_EXTERNAL_SOURCES = {
  APPLE: 'apple',
  STRIPE: 'stripe',
}
