import dynamic from 'next/dynamic'
const Toast = dynamic(() => import('styleguide/packages/components/toast'))
import { getLogger } from './log'

export const handleError = async err => {
  try {
    await toastError(new Error('We are sorry, please try again later'))
  } catch (err2) {
    trackError(err2)
  } finally {
    trackError(err)
  }
}

export const toastError = async err => {
  if (err?.message) {
    const { toast } = await import('react-toastify')
    toast(<Toast.Error message={err.message} />)
  }
}

export const trackError = (msg, err, opts = null) => {
  getExceptionLogger().then(log => log(msg, err, opts))
}

export const getExceptionLogger = async (defaultOpts = null) => {
  const logger = await getLogger(defaultOpts)
  return (msg, err, opts = null) => {
    opts = { ...opts, error: err }
    logger(msg, 'error', opts)
  }
}
